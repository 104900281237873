import actions from './actions';

const {
  CREATE_MEMBERSHIP_BEGIN,
  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_ERR,

  EDIT_MEMBERSHIP_BEGIN,
  EDIT_MEMBERSHIP_SUCCESS,
  EDIT_MEMBERSHIP_ERR,

  ADDENDUMS_MEMBERSHIP_BEGIN,
  ADDENDUMS_MEMBERSHIP_SUCCESS,
  ADDENDUMS_MEMBERSHIP_ERR,

  MARK_MEMBERSHIP_BEGIN,
  MARK_MEMBERSHIP_SUCCESS,
  MARK_MEMBERSHIP_ERR,

  GET_ALL_MEMBERSHIPS_BEGIN,
  GET_ALL_MEMBERSHIPS_SUCCESS,
  GET_ALL_MEMBERSHIPS_ERR,

  VIEW_MEMBERSHIP_BEGIN,
  VIEW_MEMBERSHIP_SUCCESS,
  VIEW_MEMBERSHIP_ERR,

  RESET_VIEW_MEMBERSHIP_DATA,

  RENEW_MEMBERSHIP_BEGIN,
  RENEW_MEMBERSHIP_SUCCESS,
  RENEW_MEMBERSHIP_ERR,

  HISTORY_BEGIN,
  HISTORY_SUCCESS,
  HISTORY_ERR,

  CREATE_MEMBER_BEGIN,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_ERR,

  LIST_MEMBERSHIPS_BEGIN,
  LIST_MEMBERSHIPS_SUCCESS,
  LIST_MEMBERSHIPS_ERR,

  LIST_MEMBERS_BEGIN,
  LIST_MEMBERS_SUCCESS,
  LIST_MEMBERS_ERR,

  SET_MEMBERSHIP_PROFILE_RADIO,

  SET_CREATE_MEMBERSHIP_STEP,

  SET_MEMBERSHIP_FORM_DETAILS,

  SET_EDIT_MEMBERSHIP_FORM_DETAILS,

  LIST_ALL_ADDENDUMS_BEGIN,
  LIST_ALL_ADDENDUMS_SUCCESS,
  LIST_ALL_ADDENDUMS_ERR,

  DELETE_ADDENDUM_BEGIN,
  DELETE_ADDENDUM_SUCCESS,
  DELETE_ADDENDUM_ERR,

  MEMBERSHIP_CARDS_BEGIN,
  MEMBERSHIP_CARDS_SUCCESS,
  MEMBERSHIP_CARDS_ERR,

  DELETE_MEMBERSHIP_BEGIN,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_ERR,

  GET_ALL_WORKSPACE_MODULES_BEGIN,
  GET_ALL_WORKSPACE_MODULES_SUCCESS,
  GET_ALL_WORKSPACE_MODULES_ERR,

  GENERATE_CREDITS_USAGE_REPORT_BEGIN,
  GENERATE_CREDITS_USAGE_REPORT_SUCCESS,
  GENERATE_CREDITS_USAGE_REPORT_ERR,

  DEACTIVATE_USER_BEGIN,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_ERR,

  GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_BEGIN,
  GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_SUCCESS,
  GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_ERR,
} = actions;

const initState = {
  memberships: null,
  allMemberships: null,
  members: null,
  loading: false,
  error: null,
  membershipProfileRadio: null,
  createMembershipSteps: {
    chooseMembership: true,
    individualDetails: false,
    companyDetails: false,
    contractDetails: false,
    spocDetails: false,
    location: false,
    additionalService: false,
  },
  membershipFormDetails: {
    zoho_contact_id: null,
    additional_zoho_contact_id: [],
    membershipFor: null,
    fullName: null,
    organisationName: null,
    startDate: {
      month: 0,
      year: 0,
    },
    endDate: {
      month: 0,
      year: 0,
    },
    totalSeats: 0,
    gstin: null,
    pan: null,
    primaryMobile: null,
    primaryEmail: null,
    autoGenerateInvoice: 25,
    registeredCompanyAddress: null,
    spocDetail: {
      spocName: null,
      spocMobile: null,
      spocEmail: null,
    },
    additionalServices: [],
    locationId: null,
    membershipType: null,
    amount: 0,
    noticePeriod: null,
    onboardingCharges: null,
    securityDeposit: null,
    lockingPeriod: null,
    annualEscalation: null,
    workspaceId: null,
    invoiceStatus: 'ACTIVE',
  },
  editMembershipFormDetails: {
    zoho_contact_id: null,
    membershipFor: null,
    fullName: null,
    organisationName: null,
    startDate: {
      month: 0,
      year: 0,
    },
    endDate: {
      month: 0,
      year: 0,
    },
    totalSeats: 0,
    gstin: null,
    pan: null,
    primaryMobile: null,
    primaryEmail: null,
    autoGenerateInvoice: 0,
    registeredCompanyAddress: null,
    spocDetail: {
      spocName: null,
      spocMobile: null,
      spocEmail: null,
    },
    additionalServices: [],
    locationId: null,
    membershipType: null,
    amount: 0,
    status: null,
    noticePeriod: null,
    onboardingCharges: null,
    securityDeposit: null,
    lockingPeriod: null,
    annualEscalation: null,
    workspaceId: null,
    invoiceStatus: null,
    cc_mail_zoho_invoice: [],
  },
  viewMembership: null,
  viewMembershipLoading: false,
  allAddendums: null,
  membershipCards: null,
  workspaceModules: null,
};

const memebershipReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_MEMBERSHIP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case CREATE_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case EDIT_MEMBERSHIP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case EDIT_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADDENDUMS_MEMBERSHIP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADDENDUMS_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case ADDENDUMS_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case MARK_MEMBERSHIP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MARK_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case MARK_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ALL_MEMBERSHIPS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        allMemberships: data,
        loading: false,
      };
    case GET_ALL_MEMBERSHIPS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_MEMBERSHIP_BEGIN:
      return {
        ...state,
        viewMembershipLoading: true,
      };
    case VIEW_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        viewMembership: data,
        viewMembershipLoading: false,
      };
    case VIEW_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        viewMembershipLoading: false,
      };
    case RESET_VIEW_MEMBERSHIP_DATA:
      return {
        ...state,
        viewMembership: null,
      };
    case RENEW_MEMBERSHIP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RENEW_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case RENEW_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case HISTORY_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case HISTORY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CREATE_MEMBER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case CREATE_MEMBER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LIST_MEMBERSHIPS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LIST_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        memberships: data,
        loading: false,
      };
    case LIST_MEMBERSHIPS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LIST_MEMBERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LIST_MEMBERS_SUCCESS:
      return {
        ...state,
        members: data,
        loading: false,
      };
    case LIST_MEMBERS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SET_MEMBERSHIP_PROFILE_RADIO:
      return {
        ...state,
        membershipProfileRadio: data,
      };
    case SET_CREATE_MEMBERSHIP_STEP:
      return {
        ...state,
        createMembershipSteps: data,
      };
    case SET_MEMBERSHIP_FORM_DETAILS:
      return {
        ...state,
        membershipFormDetails: data,
      };
    case SET_EDIT_MEMBERSHIP_FORM_DETAILS:
      return {
        ...state,
        editMembershipFormDetails: data,
      };
    case LIST_ALL_ADDENDUMS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LIST_ALL_ADDENDUMS_SUCCESS:
      return {
        ...state,
        allAddendums: data,
        loading: false,
      };
    case LIST_ALL_ADDENDUMS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_ADDENDUM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ADDENDUM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ADDENDUM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case MEMBERSHIP_CARDS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MEMBERSHIP_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        membershipCards: data,
      };
    case MEMBERSHIP_CARDS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_MEMBERSHIP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_MEMBERSHIP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ALL_WORKSPACE_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_WORKSPACE_MODULES_SUCCESS:
      return {
        ...state,
        workspaceModules: data,
        loading: false,
      };
    case GET_ALL_WORKSPACE_MODULES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GENERATE_CREDITS_USAGE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_CREDITS_USAGE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GENERATE_CREDITS_USAGE_REPORT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DEACTIVATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DEACTIVATE_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default memebershipReducer;
